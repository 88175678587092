<script lang="ts" setup>
    const props = defineProps({
        content: String,
        link: String,
        linkLabel: String,
    })

    const separatedLinkLabel = (props.linkLabel ?? '')
        .split(' ') // split the content into words
        .map((label) => `<span>${label}</span>`) // wrap each word in a span
        .join(' ') // join the words back together
</script>

<template>
    <div
        class="max-w-[350px] text-2xl uppercase lg:text-3xl"
        data-aos="fade-up"
        data-aos-delay="100"
    >
        <div v-html="content" />

        <NuxtLink :to="link" class="cta-link mt-5 block" v-if="link && linkLabel">
            <span v-html="separatedLinkLabel" />
        </NuxtLink>
    </div>
</template>

<style>
    .cta-link span span {
        white-space: nowrap;
    }

    .cta-link span span:last-child:after {
        content: '';
        position: relative;
        display: inline-block;
        background-color: currentColor;
        mask: url('@/assets/icons/arrow-northeast.svg') no-repeat center / contain;
        width: 11px;
        height: 11px;
        margin-left: 1rem;
        transition: 0.4s;
    }

    .cta-link:hover span span:last-child:after {
        transform: translate(5px, -5px);
    }
</style>
